import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { useQuery } from "react-query";
import moment from "moment";
import {
  getLotterySetFilter,
  getPeriodLotteryByPeriodYearApi,
} from "../../api/lottery";
import { IPeriodLotteryWithMainResult } from "../../interface/lottery";
import Pagination from "../../component/pagination";
import { getSrcImage } from "../../utils/utils";
import shortid from "shortid";
import {
  adminConfirmOrderApi,
  bookingOrder,
  confirmOrder,
  dealerCustomOrder,
  getCurrentOrder,
  returnOrder,
} from "../../api/order";
import { useAppSelector } from "../../redux/hooks";
import useAppSnackbar from "../../component/hooks/useAppSnackbar";
import useAppLoader from "../../component/hooks/useAppLoader";
import { filterUsersApi } from "../../api/user";
import _ from "lodash";

const jquery = require("jquery");
jquery.dropify = require("dropify");

declare let $: any;

type Props = {};
interface LotteryGroupImageProps {
  images: string[];
}
const LotteryGroupImage: FC<LotteryGroupImageProps> = ({ images }) => {
  return (
    <div
      className="w-100 h-auto position-relative"
      style={{
        aspectRatio: `365/${183 + (40 * images.length - 1)}`,
      }}
    >
      {images.map((e, i) => (
        <img
          key={`${e}-${i}`}
          src={getSrcImage(e)}
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            top: `${i * 16}%`,
          }}
        />
      ))}
    </div>
  );
};

interface filterLotteryState {
  numberList: string[];
  totalInSet?: number;
  page: number;
}

interface CreateOrderFormData {
  buyer?: string;
  slip?: string;
  isDealer: boolean;
  status?: number;
}

function CreateOrder({}: Props) {
  const { openSnackbar, openErrorSnackbar } = useAppSnackbar();
  const { showLoader, hideLoader } = useAppLoader();
  // profile
  const myProfile = useAppSelector((state) => state.auth.user);
  // get period lottery from now
  const currentYear = useAppSelector(
    (state) => state.auth.activePeriod?.period.year ?? moment().utc().year()
  );
  const { data: periodLotteryList } = useQuery({
    queryKey: ["getPeriodLotteryByPeriodYear", currentYear],
    queryFn: async () => {
      return (await getPeriodLotteryByPeriodYearApi(currentYear)).data;
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });
  const activePeriod = useMemo<IPeriodLotteryWithMainResult | null>(() => {
    if (periodLotteryList) {
      return (
        periodLotteryList.find(
          (e) =>
            moment().isBetween(
              moment(e.openDatetime),
              moment(e.closeDatetime)
            ) && e.totalLotteryResult === 0
        ) ?? null
      );
    }
    return null;
  }, [periodLotteryList]);

  const { data: currentOrder, refetch: fetchCurrentOrder } = useQuery({
    queryKey: ["getCurrentOrder"],
    queryFn: async () => {
      return (await getCurrentOrder()).data;
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const [filter, setFilter] = useState<filterLotteryState>({
    page: 1,
    numberList: ["", "", "", "", "", ""],
  });
  const { data: lotterySetData, refetch: fetchLotterySetData } = useQuery({
    queryKey: ["getLotterySeFilter", activePeriod?.id, filter],
    queryFn: async () => {
      if (activePeriod)
        return (
          await getLotterySetFilter({
            periodId: activePeriod.id,
            page: filter.page,
            number: filter.numberList.map((e) => (e === "" ? "x" : e)).join(""),
            limit: 12,
            totalInSet: filter.totalInSet,
          })
        ).data;
      return null;
    },
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });
  const [formData, setFormData] = useState<CreateOrderFormData>({
    isDealer: true,
  });
  const { data: userData, refetch: fetchUsers } = useQuery({
    queryKey: ["getUsers"],
    queryFn: async () => {
      return (
        await filterUsersApi({
          role: "user",
          status: "1",
          page: 1,
          limit: 9999999,
        })
      ).data;
    },
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  console.log("activePeriod", activePeriod);
  console.log("lotterySetData", lotterySetData);
  console.log("currentOrder", currentOrder);
  console.log("userData", userData);

  const onBooking = useCallback(
    async (lotterySetId: string) => {
      showLoader();
      try {
        if (
          currentOrder?.orderItems?.find((e) => e.lotterySetId === lotterySetId)
        ) {
          await returnOrder(lotterySetId);
          openSnackbar("คืนลอตเตอรี่สำเร็จ");
        } else {
          await bookingOrder({ lotterySetId, dealerId: myProfile.id });
          openSnackbar("จองลอตเตอรี่สำเร็จ");
        }
        await Promise.all([fetchCurrentOrder(), fetchLotterySetData()]);
      } catch (e) {
        openErrorSnackbar(e);
      }
      hideLoader();
    },
    [currentOrder]
  );

  const onSubmit = useCallback(async () => {
    console.log("onSubmit", currentOrder, formData);
    if (currentOrder) {
      if (!formData.isDealer && formData.buyer === undefined) {
        return window.alert("กรุณาเลือกผู้ซื้อ");
      }
      if (formData.status === undefined) {
        return window.alert("กรุณาเลือกสถานะ");
      }
      if (window.confirm(`กรุณาตรวจสอบรายการก่อนยืนยันคำสั่งซื้อ`)) {
        showLoader();
        try {
          await dealerCustomOrder({
            id: currentOrder.id,
            status: formData.status,
            buyer: formData.buyer,
            slip: formData.slip,
          });
          openSnackbar();
          await Promise.all([fetchCurrentOrder(), fetchLotterySetData()]);
        } catch (e) {
          openErrorSnackbar(e);
        }
        hideLoader();
      }
    } else {
      return window.alert("ไม่พบรายการสั่งซื้อปัจจุบัน");
    }
  }, [currentOrder, formData]);

  useEffect(() => {
    if (activePeriod) {
      $(document).ready(function () {
        const matcher = (params, data) => {
          // If there are no search terms, return all of the data
          if ($.trim(params.term) === "") {
            return null;
          }

          // Do not display the item if there is no 'text' property
          if (typeof data.text === "undefined") {
            return null;
          }

          // `params.term` should be the term that is used for searching
          // `data.text` is the text that is displayed for the data object
          const temp = data.text.split(" ");
          if (temp.length > 0) {
            const phone = temp[temp.length - 1];
            if (
              phone === `(${params.term})` ||
              phone === `(+66${params.term})`
            ) {
              var modifiedData = $.extend({}, data, true);
              // You can return modified objects from here
              // This includes matching the `children` how you want in nested data sets
              return modifiedData;
            }
          }

          // Return `null` if the term should not be displayed
          return null;
        };

        $(".select2-dealer").select2();
        $(".select2-buyer").select2({
          matcher: matcher,
        });
        $(".select2-buyer").on("select2:select", (e) => {
          var data = e.params.data;
          if (data.id !== "") {
            setFormData((prev) => ({ ...prev, buyer: data.id }));
          } else {
            setFormData((prev) => ({ ...prev, buyer: undefined }));
          }
        });
        $(".select2-status").select2();
        $(".select2-status").on("select2:select", (e) => {
          var data = e.params.data;
          if (data.id !== "") {
            setFormData((prev) => ({ ...prev, status: parseFloat(data.id) }));
          } else {
            setFormData((prev) => ({ ...prev, status: undefined }));
          }
        });
        const dropSlip = jquery("#dropSlip").dropify({ defaultFile: "" });

        dropSlip.on("dropify.afterClear", function () {
          setFormData((prev) => ({ ...prev, slip: "" }));
        });
      });
    }
  }, [activePeriod]);

  return (
    <div className="dashboard-header d-flex flex-column grid-margin ">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <Breadcrumb
          title={"สร้างคำสั่งซื้อ"}
          children={[
            { title: "หน้าแรก", link: "/" },
            { title: "สร้างคำสั่งซื้อ" },
          ]}
        />
      </div>
      {activePeriod ? (
        <div className="row">
          <div className="col-12 mb-3">
            <div className="card border-bottom">
              <div className="card-header h4 font-weight-bold- ">
                <i className="fa fa-search mr-2" aria-hidden="true" />
                1.ค้นหาล็อตเตอรี่
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 m-auto">
                    <div className="shadow-sm card mb-3 product">
                      <div className="card-body">
                        {/*select class="js-example-basic-single w-100 mb-2">
                                 <option value="AL" selected="">งวดวันที่ 2 มิถุนายน 2564</option>
                                 <option value="WY">งวดวันที่ 2 มิถุนายน 2564</option>
                                 <option value="AM">....</option>
                              </select*/}
                        <div className="lottery-search">
                          <div
                            className="tab-pane active flex align-center text-center"
                            id="search-1"
                          >
                            <div>
                              <h3>
                                งวดประจำวันที่ {activePeriod?.period.day ?? ""}{" "}
                                {!!activePeriod
                                  ? moment.months(
                                      activePeriod!.period.month - 1
                                    )
                                  : ""}{" "}
                                {!!activePeriod
                                  ? activePeriod!.period.year + 543
                                  : ""}
                              </h3>
                            </div>
                            <br />
                            <form className="form-default">
                              <div className="row ">
                                <div className="col-md">
                                  <div className="row gutters-20 d-flex justify-content-center">
                                    <div className="col-auto m-1">
                                      <div className="form-group">
                                        <div className="block-control">
                                          <input
                                            pattern="\d*"
                                            className="form-control text-number"
                                            type="text"
                                            id="num1"
                                            name="num1"
                                            defaultValue=""
                                            data-next="num2"
                                            placeholder=""
                                            data-error=""
                                            maxLength={1}
                                            onInput={(e) => {
                                              const target = e.currentTarget;
                                              target.value = target.value
                                                .replace(/[^0-9.]/g, "")
                                                .replace(/(\..*)\./g, "$1");
                                            }}
                                            onChange={(e) => {
                                              const target = e.currentTarget;
                                              if (target.value.length > 0) {
                                                window.document
                                                  .getElementById("num2")
                                                  ?.focus();
                                              }
                                            }}
                                            style={{
                                              width: 60,
                                              height: 60,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-auto m-1">
                                      <div className="form-group">
                                        <div className="block-control">
                                          <input
                                            pattern="\d*"
                                            className="form-control text-number"
                                            type="text"
                                            id="num2"
                                            name="num2"
                                            defaultValue=""
                                            placeholder=""
                                            data-error=""
                                            maxLength={1}
                                            onInput={(e) => {
                                              const target = e.currentTarget;
                                              target.value = target.value
                                                .replace(/[^0-9.]/g, "")
                                                .replace(/(\..*)\./g, "$1");
                                            }}
                                            onKeyUp={(e) => {
                                              const target = e.currentTarget;
                                              if (
                                                target.value.length === 0 &&
                                                (e.key === "Backspace" ||
                                                  e.key === "Delete")
                                              ) {
                                                window.document
                                                  .getElementById("num1")
                                                  ?.focus();
                                              }
                                            }}
                                            onChange={(e) => {
                                              const target = e.currentTarget;
                                              if (target.value.length > 0) {
                                                window.document
                                                  .getElementById("num3")
                                                  ?.focus();
                                              } else {
                                                window.document
                                                  .getElementById("num1")
                                                  ?.focus();
                                              }
                                            }}
                                            style={{
                                              width: 60,
                                              height: 60,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-auto m-1">
                                      <div className="form-group">
                                        <div className="block-control">
                                          <input
                                            pattern="\d*"
                                            className="form-control text-number"
                                            type="text"
                                            id="num3"
                                            name="num3"
                                            defaultValue=""
                                            placeholder=""
                                            data-error=""
                                            maxLength={1}
                                            onInput={(e) => {
                                              const target = e.currentTarget;
                                              target.value = target.value
                                                .replace(/[^0-9.]/g, "")
                                                .replace(/(\..*)\./g, "$1");
                                            }}
                                            onKeyUp={(e) => {
                                              const target = e.currentTarget;
                                              if (
                                                target.value.length === 0 &&
                                                (e.key === "Backspace" ||
                                                  e.key === "Delete")
                                              ) {
                                                window.document
                                                  .getElementById("num2")
                                                  ?.focus();
                                              }
                                            }}
                                            onChange={(e) => {
                                              const target = e.currentTarget;
                                              if (target.value.length > 0) {
                                                window.document
                                                  .getElementById("num4")
                                                  ?.focus();
                                              } else {
                                                window.document
                                                  .getElementById("num2")
                                                  ?.focus();
                                              }
                                            }}
                                            style={{
                                              width: 60,
                                              height: 60,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-auto m-1">
                                      <div className="form-group">
                                        <div className="block-control">
                                          <input
                                            pattern="\d*"
                                            className="form-control text-number"
                                            type="text"
                                            id="num4"
                                            name="num4"
                                            defaultValue=""
                                            placeholder=""
                                            data-error=""
                                            maxLength={1}
                                            onInput={(e) => {
                                              const target = e.currentTarget;
                                              target.value = target.value
                                                .replace(/[^0-9.]/g, "")
                                                .replace(/(\..*)\./g, "$1");
                                            }}
                                            onKeyUp={(e) => {
                                              const target = e.currentTarget;
                                              if (
                                                target.value.length === 0 &&
                                                (e.key === "Backspace" ||
                                                  e.key === "Delete")
                                              ) {
                                                window.document
                                                  .getElementById("num3")
                                                  ?.focus();
                                              }
                                            }}
                                            onChange={(e) => {
                                              const target = e.currentTarget;
                                              if (target.value.length > 0) {
                                                window.document
                                                  .getElementById("num5")
                                                  ?.focus();
                                              } else {
                                                window.document
                                                  .getElementById("num3")
                                                  ?.focus();
                                              }
                                            }}
                                            style={{
                                              width: 60,
                                              height: 60,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-auto m-1">
                                      <div className="form-group">
                                        <div className="block-control">
                                          <input
                                            pattern="\d*"
                                            className="form-control text-number"
                                            type="text"
                                            id="num5"
                                            name="num5"
                                            defaultValue=""
                                            placeholder=""
                                            data-error=""
                                            maxLength={1}
                                            onInput={(e) => {
                                              const target = e.currentTarget;
                                              target.value = target.value
                                                .replace(/[^0-9.]/g, "")
                                                .replace(/(\..*)\./g, "$1");
                                            }}
                                            onKeyUp={(e) => {
                                              const target = e.currentTarget;
                                              if (
                                                target.value.length === 0 &&
                                                (e.key === "Backspace" ||
                                                  e.key === "Delete")
                                              ) {
                                                window.document
                                                  .getElementById("num4")
                                                  ?.focus();
                                              }
                                            }}
                                            onChange={(e) => {
                                              const target = e.currentTarget;
                                              if (target.value.length > 0) {
                                                window.document
                                                  .getElementById("num6")
                                                  ?.focus();
                                              } else {
                                                window.document
                                                  .getElementById("num4")
                                                  ?.focus();
                                              }
                                            }}
                                            style={{
                                              width: 60,
                                              height: 60,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-auto m-1">
                                      <div className="form-group">
                                        <div className="block-control">
                                          <input
                                            pattern="\d*"
                                            className="form-control text-number"
                                            type="text"
                                            id="num6"
                                            name="num6"
                                            defaultValue=""
                                            placeholder=""
                                            data-error=""
                                            maxLength={1}
                                            onInput={(e) => {
                                              const target = e.currentTarget;
                                              target.value = target.value
                                                .replace(/[^0-9.]/g, "")
                                                .replace(/(\..*)\./g, "$1");
                                            }}
                                            onKeyUp={(e) => {
                                              const target = e.currentTarget;
                                              if (
                                                target.value.length === 0 &&
                                                (e.key === "Backspace" ||
                                                  e.key === "Delete")
                                              ) {
                                                window.document
                                                  .getElementById("num5")
                                                  ?.focus();
                                              }
                                            }}
                                            onChange={(e) => {
                                              const target = e.currentTarget;
                                              if (target.value.length > 0) {
                                                window.document
                                                  .getElementById(
                                                    "search-lottery-btn"
                                                  )
                                                  ?.focus();
                                              } else {
                                                window.document
                                                  .getElementById("num5")
                                                  ?.focus();
                                              }
                                            }}
                                            style={{
                                              width: 60,
                                              height: 60,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 mb-2">
                            <button
                              id="search-lottery-btn"
                              className="btn btn-primary btn-block"
                              onClick={() => {
                                const inputs =
                                  document.getElementsByClassName(
                                    "text-number"
                                  );
                                const temp: string[] = [];
                                _.times(inputs.length, (i) => {
                                  temp.push(
                                    (inputs[i] as HTMLInputElement).value
                                  );
                                });
                                setFilter((prev) => ({
                                  ...prev,
                                  numberList: [...temp],
                                }));
                              }}
                            >
                              ค้นหา
                            </button>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                const val = parseFloat(e.currentTarget.value);
                                if (val === 0)
                                  setFilter((prev) => ({
                                    ...prev,
                                    totalInSet: undefined,
                                    page: 1,
                                  }));
                                else
                                  setFilter((prev) => ({
                                    ...prev,
                                    totalInSet: val,
                                    page: 1,
                                  }));
                              }}
                            >
                              <option value={0}>ทั้งหมด</option>
                              {_.times(5, (i) => (
                                <option key={`set-${i + 1}`} value={i + 1}>
                                  ชุด {i + 1} ใบ
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/*div class="input-group mt-2">
               <input pattern="\d*" class="form-control " type="text" id="num" name="num" value="" pattern="[0-9]+"  placeholder="ค้นหาล็อตเตอรี่" data-error="" maxlength="6" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                <div class="input-group-append">
                  <button class="btn btn-sm btn-outline-primary" type="button"><i class="fa fa-search p-0 m-0" aria-hidden="true"></i></button>
                </div>
              </div*/}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    {/*div class="row mb-2 pb-2">
                              <div class="col text-muted tx-12 ml-2">หมายเลข</div>
                              <div class="col text-muted tx-12">ชุด/จำนวน</div>
                              <div class="col text-muted tx-12"></div>
                          </div*/}
                    <div className="overflow-auto">
                      <div className="row">
                        {lotterySetData?.results?.map((e) => (
                          <div
                            className="col-lg-3 card_lotto pb-2"
                            key={`${e.id}`}
                          >
                            <div className="card border-bottom h-100">
                              <div className="card-body p-0 d-flex flex-column justify-content-between">
                                <div className="row m-0 p-0">
                                  <div className="col-12 p-0">
                                    <LotteryGroupImage images={e.pictures} />
                                  </div>
                                </div>
                                <div className="row p-2">
                                  <div className="col">
                                    <span className="text-muted tx-11">
                                      หมายเลข
                                    </span>
                                    <h3 className="c-black">{e.number}</h3>
                                  </div>
                                  <div className="col text-center">
                                    <span className="text-muted tx-11">
                                      ชุด
                                    </span>
                                    <h3 className="c-black">{e.totalInSet}</h3>
                                  </div>
                                  <div className="col-auto">
                                    <button
                                      className="btn btn-outline-primary mt-2"
                                      type="button"
                                      onClick={() => {
                                        onBooking(e.id);
                                      }}
                                    >
                                      {currentOrder?.orderItems?.find(
                                        (c) => c.lotterySetId === e.id
                                      )
                                        ? "เลือกแล้ว"
                                        : "เลือก"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 d-flex justify-content-center">
                  <Pagination
                    active={lotterySetData?.meta.page ?? filter.page}
                    limit={12}
                    onPageChange={(page) => {
                      setFilter((prev) => ({ ...prev, page }));
                    }}
                    pageRangeDisplayed={10}
                    total={lotterySetData?.meta.totalCount ?? 0}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-header h4 font-weight-bold- ">
                <i className="fa fa-shopping-cart mr-2" aria-hidden="true" />
                2.ตะกร้าสินค้า
              </div>
              <div className="card-body">
                <div className="overflow-auto" style={{ height: 485 }}>
                  <div className="row mb-2 pb-2">
                    <div className="col text-muted tx-12  ml-2">หมายเลข</div>
                    <div className="col text-muted tx-12">ชุด/จำนวน</div>
                    <div className="col text-muted tx-12">ราคา</div>
                    <div className="col text-muted tx-12">ค่าบริการ</div>
                    <div className="col text-muted tx-12">รวม</div>
                    <div className="col-auto text-muted tx-12">ดำเนินการ</div>
                  </div>

                  {currentOrder?.orderItems?.map((e) => (
                    <div className="row" key={e.lotterySetId}>
                      <div className="col">
                        <div className="card border-bottom mb-2">
                          <div className="card-body p-2">
                            <div className="row ">
                              <div className="col pt-2">
                                <h3 className="c-black">
                                  {e.lotterySet.number}
                                </h3>
                              </div>
                              <div className="col pt-2">
                                {e.lotterySet.totalInSet}
                              </div>
                              <div className="col pt-2">{e.price}</div>
                              <div className="col pt-2">{e.serviceCharge}</div>
                              <div className="col pt-2">
                                {e.price + e.serviceCharge}
                              </div>
                              <div className="col-auto">
                                <button
                                  className="btn btn-outline-primary"
                                  type="button"
                                  onClick={() => {
                                    onBooking(e.lotterySetId);
                                  }}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/*div class="cart"></div*/}
                </div>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="ml-auto text-right">
                    <span className="h4 font-weight-bold-">
                      {`รวมทั้งหมด ${
                        currentOrder?.orderItems?.reduce(
                          (a, b) => (a += b.lotterySet.totalInSet),
                          0
                        ) ?? 0
                      } ใบ`}
                    </span>
                    <br />
                    <span className="h2 font-weight-bold- c-black ">
                      {`ราคาทั้งหมด ฿${
                        currentOrder?.orderItems
                          ?.reduce(
                            (a, b) => (a += b.price + b.serviceCharge),
                            0
                          )
                          .toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }) ?? 0
                      }`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-header h4 font-weight-bold- ">
                <i className="fa fa fa-flag mr-2" aria-hidden="true" />
                3.รายละเอียดคำสั่งซื้อ
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className=" form-group">
                      <p className=" text-muted mb-2">1.ผู้ซื้อ</p>
                      <ul
                        className="nav nav-pills nav-pills-success"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li
                          className="nav-item"
                          onClick={() => {
                            setFormData((prev) => ({
                              ...prev,
                              buyer: undefined,
                              isDealer: true,
                            }));
                          }}
                        >
                          <a
                            className={`nav-link ${
                              formData.isDealer ? "active" : ""
                            }`}
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="false"
                          >
                            ตัวแทน
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            setFormData((prev) => ({
                              ...prev,
                              isDealer: false,
                            }));
                          }}
                        >
                          <a
                            className={`nav-link ${
                              formData.isDealer ? "" : "active"
                            }`}
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="true"
                          >
                            ลูกค้า
                          </a>
                        </li>
                      </ul>
                      <div
                        className="tab-content p-0 border-0"
                        id="pills-tabContent"
                      >
                        <div
                          className={`tab-pane ${
                            formData.isDealer ? "active" : ""
                          }`}
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <select
                            className="select2-dealer w-100"
                            tabIndex={-1}
                            aria-hidden="true"
                            disabled
                          >
                            <option value={undefined}>
                              {`${myProfile.firstName} ${myProfile.lastName} (${
                                myProfile?.dealerInfo?.name ?? "ไม่ระบุชื่อ"
                              })`}
                            </option>
                          </select>
                        </div>
                        <div
                          className={`tab-pane ${
                            formData.isDealer ? "" : "active"
                          }`}
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <select
                            className="select2-buyer w-100"
                            style={{ width: "100%" }}
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            <option value="">ค้นหาด้วยเบอร์โทรศัพท์</option>
                            {!formData.isDealer &&
                              userData?.results?.map((e) => (
                                <option key={e.id} value={e.id}>
                                  {`${e.firstName} ${e.lastName} (${e.phoneNumber})`}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <p className="text-muted mb-2">2.ผู้ขาย</p>
                      <select
                        className="select2-dealer w-100"
                        tabIndex={-1}
                        aria-hidden="true"
                        disabled
                      >
                        <option value={undefined}>
                          {`${myProfile.firstName} ${myProfile.lastName} (${
                            myProfile?.dealerInfo?.name ?? "ไม่ระบุชื่อ"
                          })`}
                        </option>
                      </select>

                      {/*input type="text" class="form-control mt-2" placeholder="เบอร์โทรศัพท์"*/}
                    </div>
                  </div>
                  <div className="col mb-4">
                    <p className="text-muted mb-2">2.แนปสลิป</p>
                    <input
                      id="dropSlip"
                      type="file"
                      className="dropify"
                      data-max-file-size="5M"
                      data-allowed-file-extensions="jpg png jpeg"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setFormData((prev) => ({
                            ...prev,
                            slip: `${reader.result}`,
                          }));
                        };
                      }}
                      style={{ minHeight: 500, height: 500 }}
                    />
                  </div>
                  <div className="col mb-4">
                    <p className="text-muted mb-2">3.สถานะ</p>
                    <select
                      className="select2-status w-100"
                      style={{ width: "100%" }}
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option value="">เลือกสถานะ</option>
                      <option value={2}>จอง</option>
                      <option value={5}>รอยืนยัน</option>
                      <option value={1}>สำเร็จ</option>
                    </select>
                  </div>
                  <div className="col-12 border-top pt-4">
                    <div className="row">
                      {/*button class="btn btn-outline-primary ml-auto mr-2">จองเลข</button*/}
                      <button
                        className="btn btn-success ml-auto mr-2"
                        onClick={onSubmit}
                      >
                        สร้างคำสั่งซื้อ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CreateOrder;
